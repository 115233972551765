body {
  background-color: #080808;
  margin: 0;
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #fff;
}

.logo-container img {
  height: 55px;
  margin-right: 10px;
  margin-top: -10px;
}

.logo-container .powell-logo {
  height: 30px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

h1, h2, h3, h5, h6 {
  padding-bottom: 20px;
}

h1 {
  font-size: 32px; /* Was 24px */
  margin-bottom: 0.5em;
}

h2 {
  font-size: 2.2em; /* Was 2em */
}

.hero-section {
  background-size: cover;
  background-position: center;
  filter: blur(15%);
  height: 100vh;
}

.container {
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #fff;
  padding: 0 80px;
}

.navbar {
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.9);
  padding: 1.5em 4em;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  width: 100%;
  box-sizing: border-box;
}

.logo-container, .contact-button {
  position: absolute;
}

.logo-container {
  left: 10px;
}

.contact-button {
  right: 2em;
}

.nav-links a:hover, .contact-button a:hover {
  color: #fff;
  transition: color 0.3s ease-in-out;
}

.nav-links {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex: 1;
  gap: 20px;
}

.contact-button a, .nav-links a {
  background-color: transparent;
  color: #c0c0c0;
  padding: 0;
  text-decoration: none;
  border-radius: 0;
  cursor: pointer;
  font-size: 0.8em;
}

@media (max-width: 768px) {
  .navbar {
    padding: 2em 4em;
  }

  .nav-links {
    display: none;
  }

  .burger-menu {
    display: block;
    cursor: pointer;
    position: absolute;
    right: 20px;
    top: 20px;
  }

  .burger-menu span {
    display: block;
    width: 25px;
    height: 3px;
    background-color: #333;
    margin: 5px 0;
    transition: 0.4s;
  }

  .nav-links.show {
    padding-top: 20px;
    padding-bottom: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: rgba(14, 14, 14, 0.95);
    position: absolute;
    width: 100%;
    top: 100%;
    left: 0;
  }

  .burger-menu.open span:nth-child(1) {
    transform: rotate(45deg) translate(5px, 5px);
  }

  .burger-menu.open span:nth-child(2) {
    opacity: 0;
  }

  .burger-menu.open span:nth-child(3) {
    transform: rotate(-45deg) translate(7px, -6px);
  }
}

.about_section, .products_and_services_section, .mission_section {
  height: auto;
  padding: 20px 0;
}

.about_section .container, .products_and_services_section .container, .mission_section .container {
  padding: 0 10px;
}

.about_section p, .products_and_services_section p, .mission_section p, .mission_statement p {
  font-size: 18px;
}

@media (max-width: 768px) {
  .about_section h1, .products_and_services_section h1, .mission_section h1 {
    font-size: 20px;
  }

  .about_section p, .products_and_services_section p, .mission_section p, .mission_statement p {
    font-size: 16px;
  }
}

.team-section {
  padding-top: 1em;
  padding-bottom: 6em;
}

.team-member {
  cursor: pointer;
  padding: 10px;
  position: relative;
}

.team-member:hover {
  background-color: #222;
}

.divider {
  border-top: 1px solid #ccc;
  width: 90%;
  position: absolute;
  left: 5%;
  bottom: 0;
}

.team-member-description {
  padding: 0 20px;
}

.member-details {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px 8em 20px;
}

.member-details img {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  object-fit: cover;
  margin-bottom: 20px;
  position: relative;
  overflow: hidden;
}

.team-member h3,
.team-member h4 {
  padding-bottom: 0;
  margin-bottom: 0;
  font-size: 1.2em; /* Adjust as needed */
}

.team-member h4 {
  color: #c6c6c6;
  padding-bottom: 20px;
}

.title-expanded {
  padding-bottom: 0;
}

.mission_section {
  position: relative;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-size: cover;
  background-position: center;
  padding: 20px 0;
}

.mission_section .container {
  max-width: 1200px;
  margin: auto;
  padding: 0 10px;
}

.mission_content {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  gap: 20px;
}

.mission_image {
  max-width: 50%;
}

.mission_image img {
  width: 100%;
  height: 80%;
  object-fit: cover;
  max-width: 100%;
  border-radius: 10px;
}

.mission_statements {
  width: 80%;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.mission_statement {
  background: rgba(28, 28, 28, 0.1);
  padding: 20px;
}

.mission_section h1 {
  padding-bottom: 40px;
}

@media (max-width: 768px) {
  .mission_content {
    flex-direction: column;
    align-items: center;
  }

  .mission_image img, .mission_statements {
    width: 100%;
  }
}

.mission_statement p {
  font-size: 1.2em;
  max-width: 60ch;
  margin: auto;
}

p {
  max-width: 60ch;
  margin-left: auto;
  margin-right: auto;
}

.footer {
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.9);
  padding: 20px 80px;
  background-size: cover;
  background-position: center;
  color: #fff;
}

.footer .contact-link {
  color: #fff;
  font-size: 1em;
  background-color: transparent;
  padding: 10px 20px;
  border: 2px solid #fff;
  border-radius: 5px;
  text-decoration: none;
  transition: background-color 0.3s, color 0.3s;
}

.footer .contact-link:hover {
  background-color: #fff;
  color: #000;
  text-decoration: none;
}

@media (max-width: 768px) {
  .navbar {
    padding: 2em 2em;
  }

  .nav-links {
    display: none;
  }

  .burger-menu {
    display: block;
    cursor: pointer;
    position: absolute;
    right: 10px;
    top: 10px;
  }

  .burger-menu span {
    width: 20px;
    height: 2px;
    background-color: #333;
    margin: 4px 0;
  }

  .logo-container {
    left: 5px;
  }

  .logo-container img, .logo-container .powell-logo {
    height: 40px;
    margin-top: -5px;
  }

  .contact-button a, .nav-links a {
    font-size: 0.7em;
  }
}
.read-more-link {
  color: #fff; /* Assuming white text color as in the NavBar links */
  font-size: 0.8em; /* Match the font size of NavBar links */
  background-color: transparent;
  padding: 10px 20px; /* Adjust padding as needed */
  border: 2px solid #fff; /* Assuming a white border as in the contact link */
  border-radius: 5px; /* Rounded corners */
  text-decoration: none;
  transition: background-color 0.3s, color 0.3s; /* Smooth transition for hover effects */
}

.read-more-link:hover {
  background-color: #fff; /* Background color change on hover */
  color: #000; /* Text color change on hover */
}

.product-service {
  /* Styles for the product/service component */
  padding: 20px;
  margin-bottom: 20px;
  margin-left: auto; /* Center the component horizontally */
  margin-right: auto; /* Center the component horizontally */
  width: 90%; /* Adjust the width to make the component smaller on the sides */
  border-radius: 10px;
  background-color: #080808; /* Off-blackish background similar to the body */
  color: #fff; /* White text */
  border: none; /* Remove the border */
}

.image-gallery {
  display: flex;
  overflow-x: auto;
  gap: 10px;
  padding-top: 20px;
}

.image-gallery img {
  max-width: 100%;
  height: auto;
  border-radius: 5px;
}

.asset-gallery img {
  max-width: 100%;
  height: auto;
  border-radius: 5px;
}

.asset-gallery video {
  max-width: 100%;
  height: auto;
}

.products-services-navbar {
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.9);
  padding: 1.5em 4em;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  width: 100%;
}

.products-services-navbar .logo-container img {
  height: 55px; /* Adjust if necessary */
  margin-right: 20px; /* Ensures spacing between logo and contact link */
}

.contact-link-ps {
  color: #fff;
  font-size: 1em;
  background-color: transparent;
  padding: 10px 20px;
  border: 2px solid #fff;
  border-radius: 5px;
  text-decoration: none;
  transition: background-color 0.3s, color 0.3s;
}

.contact-link-ps:hover {
  background-color: #fff;
  color: #000;
  text-decoration: none;
}

.products-and-services-page {
  padding-top: 100px; /* Adjust the value as needed */
}
